import { LoadableLazy } from '@confluence/loadable';

export const BlogsToggledOffByPTLChangeboarding = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BlogsToggledOffByPTLChangeboarding" */ '../src/BlogsToggledOffByPTLChangeboarding'
			)
		).BlogsToggledOffByPTLChangeboarding,
});
