import type { FC, ReactElement } from 'react';
import React, { Fragment, memo, useCallback, useContext, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import type { ApolloError } from 'apollo-client';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import memoizeOne from 'memoize-one';

import { NavigationContent, SideNavigation } from '@atlaskit/side-navigation';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { APP_NAV_CONTAINER_EXPERIENCE, ExperienceSuccess } from '@confluence/experience-tracker';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { usePageSpaceKey } from '@confluence/page-context';
import { PersistentUpgradeButton } from '@confluence/persistent-upgrade';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { GeneralShortcutListener, SPACE_OVERVIEW_SHORTCUT } from '@confluence/shortcuts';
import { SpaceViewsController } from '@confluence/space-views';
import {
	useSSRPlaceholderReplaceIdProp,
	LoadableAfterPaint,
	LoadableLazy,
} from '@confluence/loadable';
import {
	isSpaceNotFoundError,
	isSpaceRestrictedError,
	RestrictionsDialogQuery,
} from '@confluence/restrictions';
import { getApolloClient, isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import {
	PERFORMANCE_SUBJECT_navigation,
	PERFORMANCE_SUBJECT_sideNavigationFMP,
	PerformanceEnd,
	PerformanceStart,
} from '@confluence/performance';
import {
	getUserPermissionFromQuery,
	GuestRequestToUpgradeSideNav,
} from '@confluence/external-collab-ui';
import { PageSegmentLoadEnd, PageSegmentLoadStart } from '@confluence/browser-metrics';
import { FORGE_MODULE_SPACE_PAGE } from '@confluence/forge-ui/entry-points/ForgeModuleType';
import { useExtensionList } from '@confluence/forge-ui/entry-points/useExtensionList';
import { ShortcutsSection } from '@confluence/space-shortcuts/entry-points/shortcutsSection';
import { MigrateShortcutsChangeboarding } from '@confluence/space-shortcuts/entry-points/migrateShortcutsChangeboarding';
import {
	confluenceSessionStorageInstance as sessionStorage,
	keys as sessionStorageKeys,
} from '@confluence/storage-manager';
import { getMonitoringClient } from '@confluence/monitoring';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { FocusToCurrentPageTreeLinkItemSSRInlineScript } from '@confluence/page-tree/entry-points/FocusToCurrentPageTreeLinkItemSSRInlineScript';
import { PersistentInvitePeopleButton } from '@confluence/persistent-invite-button';
import { PageTreeLoaderOnHover } from '@confluence/page-tree';
import { START_TOUCH } from '@confluence/navdex';
import { SPAViewContext } from '@confluence/spa-view-context';
import { ConfluenceEdition } from '@confluence/change-edition';
import { useIsElevatedAutomationUpsellEnabled } from '@confluence/change-edition/entry-points/useIsElevateAutomationEnabled';
import {
	useOnboardingState,
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_SIDE_NAV_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { BlogTree } from '@confluence/blog-tree/entry-points/BlogTree';
import { useIsBlogTreeUnderContentTreeFFEnabled } from '@confluence/blog-tree/entry-points/useIsBlogTreeUnderContentTreeFFEnabled';
import { BlogsToggledOffByPTLChangeboarding } from '@confluence/blog-tree/entry-points/BlogsToggledOffByPTLChangeboarding';
import { BLOG_TREE_METRIC } from '@confluence/blog-tree/entry-points/blogPerfMetric';

import { SpaceNavigationQuery } from './SpaceNavigationQuery.graphql';
import { SpaceHeader } from './SpaceHeader';
import { SpaceLinks } from './SpaceLinks';
import { getAllAppLinks } from './space-apps-helpers';
import type {
	SpaceNavigationQuery as SpaceNavigationQueryType,
	SpaceNavigationQuery_space,
	SpaceNavigationQuery_spaceSidebarLinks_main,
	SpaceNavigationQuery_spaceSidebarLinks_quick,
	SpaceNavigationQueryVariables,
} from './__types__/SpaceNavigationQuery';
import { SitePermissionType } from './__types__/SpaceNavigationQuery';
import { SpaceHeaderSkeleton } from './SpaceHeaderSkeleton';
import { ContainerSkeleton } from './ContainerSkeleton';
import {
	SPACE_APPS_METRIC,
	SPACE_NAVIGATION_METRIC,
	SPACE_NAVIGATION_QUERY_METRIC,
} from './perf.config';
import {
	SHORTCUTS_KEY,
	AUTOMATION_KEY,
	ANALYTICS_KEY,
	CALENDARS_KEY,
	QUESTIONS_KEY,
	DATABASES_KEY,
	BULK_TOOLS_KEY,
	BLOG_KEY,
} from './webItemCompleteKeys';
import { useIsRelocatingPremiumFeaturesExperimentEnabled } from './useIsRelocatingPremiumFeaturesExperimentEnabled';

const ErrorView = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ErrorView" */ './ErrorView')).ErrorView,
});

const SpaceApps = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpaceApps" */ './SpaceApps')).SpaceApps,
});

const AdvancedFeatures = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-AdvancedFeatures" */ './AdvancedFeatures'))
			.AdvancedFeatures,
});

const SPACE_NAV_ID = 'app-navigation-space-container';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceLinksContainer = styled.ul({
	margin: token('space.0', '0px'),
	padding: token('space.0', '0px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '18px',
	paddingTop: token('space.025', '2px'),
	listStyle: 'none',
	//eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > li': {
		marginTop: '0',
	},
});

const SidebarContent = memo(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	styled.div({
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		paddingBottom: '26px',
		position: 'relative',
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceNavigationContainer = styled.div({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	flexBasis: 'max-content',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-exit-to] > div > div > div': {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	nav: {
		backgroundColor: token('elevation.surface', N0),
		/* dividers below space header*/
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'div:nth-child(1)::before': {
			backgroundColor: token('elevation.surface', N0),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'div:nth-child(2)::before': {
			right: '15px',
		},
	},
	/* space header*/
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[data-navheader='true']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		padding: '10px 8px 4px 8px !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > div': {
			height: '40px',
			margin: '0px',
		},
	},
});

const i18n = defineMessages({
	navRegionLabel: {
		id: 'side-navigation.space-navigation.nav.region.aria.label',
		defaultMessage: 'Space',
		description: 'A label for the left-side navigation region',
	},
});

export type SpaceNavigationProps = {
	isBlogNavigation?: boolean;
	isSpaceSettingsScreen?: boolean;
};

type PreloadSpaceNavigationResult = {
	loading: boolean;
	hasRestrictedError: boolean;
	hasNotFoundError: boolean;
	unhandledError?: ApolloError;
	hasSpace: boolean;
	space?: SpaceNavigationQuery_space;
	name?: string;
	iconPath?: string;
	isSpaceAdmin: boolean;
	homepageId?: string;
	containsExternalCollaborators: boolean;
	main: SpaceNavigationQuery_spaceSidebarLinks_main[];
	quick: SpaceNavigationQuery_spaceSidebarLinks_quick[];
	isUserExternalCollaborator: boolean;
};

// This will stop the space nav metric whenever the query completes,
// regardless of whether Space nav is still mounted when this happens.
const useSpaceNavQueryTiming = (spaceKey: string, isLicensed: boolean) => {
	const apollo = useApolloClient();
	useEffect(() => {
		SPACE_NAVIGATION_QUERY_METRIC.start();
		apollo
			.watchQuery({
				query: SpaceNavigationQuery,
				variables: { spaceKey, isLicensed },
			})
			.subscribe(({ loading }) => {
				if (!loading) {
					SPACE_NAVIGATION_QUERY_METRIC.stop();
				}
			});
	}, [apollo, spaceKey, isLicensed]);
};

const createResult = memoizeOne(
	(
		loading: boolean,
		data: SpaceNavigationQueryType | undefined,
		error: ApolloError | undefined,
	) => {
		const result: PreloadSpaceNavigationResult = {
			loading,
			hasSpace: false,
			isSpaceAdmin: false,
			containsExternalCollaborators: false,
			main: [],
			quick: [],
			isUserExternalCollaborator: false,
			hasRestrictedError: false,
			hasNotFoundError: false,
		};

		if (loading) {
			return result;
		}

		if (error) {
			if (isSpaceRestrictedError(error)) {
				result.hasRestrictedError = true;
			} else if (isSpaceNotFoundError(error)) {
				result.hasNotFoundError = true;
			} else {
				result.unhandledError = error;
			}
			return result;
		}

		result.hasSpace = Boolean(data?.space ?? result.hasSpace);
		result.space = data?.space || undefined;
		result.name = data?.space?.name || undefined;
		result.iconPath = `${CONTEXT_PATH}${data?.space?.icon?.path}`;
		result.isSpaceAdmin = data?.space?.currentUser?.isAdmin ?? result.isSpaceAdmin;
		result.homepageId = data?.space?.homepage?.id || undefined;
		result.containsExternalCollaborators =
			data?.space?.containsExternalCollaborators ?? result.containsExternalCollaborators;

		result.main = data?.spaceSidebarLinks?.main?.filter(isNonNullable) ?? result.main;
		result.quick = data?.spaceSidebarLinks?.quick?.filter(isNonNullable) ?? result.quick;
		result.isUserExternalCollaborator =
			getUserPermissionFromQuery(data) === SitePermissionType.EXTERNAL;

		return result;
	},
);

const usePreloadSpaceNavigation = (spaceKey: string, isLicensed: boolean) => {
	const { loading, data, error } = useQuery<
		SpaceNavigationQueryType,
		SpaceNavigationQueryVariables
	>(SpaceNavigationQuery, {
		errorPolicy: 'all',
		variables: {
			spaceKey,
			isLicensed,
		},
	});
	useSpaceNavQueryTiming(spaceKey, isLicensed);

	const result = createResult(loading, data, error);

	if (loading) return result;

	if (error) {
		if (isSpaceRestrictedError(error)) {
			markErrorAsHandled(error);
		} else if (isSpaceNotFoundError(error)) {
			markErrorAsHandled(error);
		}
	}

	return result;
};

const useOldRestrictionsButtonRefetch = () => {
	const refetch = useCallback((contentId: any) => {
		/**
		 * When the PageTree child component informs us that a page has been
		 * moved in the hierarchy, we execute a fetch of its new permissions
		 * to purge any stale permissions information that the cache may have
		 * held for that page. Not doing so would mean showing stale state for
		 * its padlock icon, and for its restriction dialog, when raised.
		 */
		void getApolloClient().query({
			query: RestrictionsDialogQuery,
			variables: {
				contentId,
			},
			fetchPolicy: 'network-only',
		});
	}, []);

	return refetch;
};

// Determines what combination of these web items can be shown to the current user
const getPremiumFeaturesWebItemKeys = (
	isSpaceAdmin: boolean,
	isSiteAdmin: boolean,
	isSiteAdminLoading: boolean | undefined,
	edition: ConfluenceEdition | null,
	isElevationAutomationUpsellEnabled: boolean,
) => {
	const webItemKeys = [ANALYTICS_KEY, QUESTIONS_KEY, DATABASES_KEY];

	if (isSpaceAdmin) {
		if (edition === ConfluenceEdition.PREMIUM || isElevationAutomationUpsellEnabled) {
			webItemKeys.push(AUTOMATION_KEY);
		}
		if (edition === ConfluenceEdition.PREMIUM) {
			webItemKeys.push(BULK_TOOLS_KEY);
		}
	}

	/**
	 * From TeamCalendarNavigation:
	 * - Premium -> true, TC is a premium feature
	 * - Standard -> true, iff user is Org/Site Admin, otherwise, false
	 * - Free -> true, TC upsell to all Free users
	 */
	if (edition === ConfluenceEdition.STANDARD) {
		if (isSiteAdmin && !isSiteAdminLoading) {
			webItemKeys.push(CALENDARS_KEY);
		}
	} else {
		webItemKeys.push(CALENDARS_KEY);
	}

	return webItemKeys;
};

export const SpaceNavigation: FC<SpaceNavigationProps> = memo(
	({ isBlogNavigation, isSpaceSettingsScreen = false }) => {
		const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
		const intl = useIntl();
		const { isLicensed, edition, isLoggedIn } = useSessionData();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { isSiteAdmin, loading: isSiteAdminLoading } = useContext(SPAViewContext);
		const { setOnboardingState } = useOnboardingState();

		const isMigrateShortcutsEnabled = useBooleanFeatureFlag(
			'confluence.frontend.migrate-shortcuts-to-smart-links-in-tree',
		);
		const isMigrateShortcutsChangeboardingEnabled = useBooleanFeatureFlag(
			'confluence.frontend.migrate-shortcuts-to-smart-links-in-tree-changeboarding',
		);

		const isMoreFeaturesEnabled = useIsRelocatingPremiumFeaturesExperimentEnabled({
			edition,
			isLoggedIn,
		});

		const isBlogTreeUnderContentTreeFFEnabled = useIsBlogTreeUnderContentTreeFFEnabled();
		const isBlogsTurnedOffFromPTLJobChangeboardingEnabled = FeatureGates.checkGate(
			'blogs_turned_off_by_PTL_job_changeboarding',
		);
		const [stateSpaceKey] = usePageSpaceKey();

		// @ts-ignore FIXME: `stateSpaceKey` can be `undefined` here, and needs proper handling
		const spaceKey: string = stateSpaceKey;

		const { push, match } = useContext(RoutesContext);

		// On a successful move, tell the restrictions button icon that it may need
		// to be updated
		const onDragDropSuccess = useOldRestrictionsButtonRefetch();

		const onOverviewShortcutTrigger = useCallback(() => {
			push(`/wiki/spaces/${spaceKey}/overview`);
		}, [push, spaceKey]);

		const handleMouseEnter = () => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'entered',
					actionSubject: 'spaceNavigation',
					source: match?.name,
					attributes: {
						navdexPointType: START_TOUCH,
					},
				},
			}).fire();
		};

		const {
			loading,
			hasRestrictedError,
			hasNotFoundError,
			unhandledError,
			hasSpace,
			name,
			space,
			iconPath,
			isSpaceAdmin,
			homepageId,
			containsExternalCollaborators,
			main,
			quick,
			isUserExternalCollaborator,
		} = usePreloadSpaceNavigation(spaceKey, isLicensed);

		const isElevationAutomationUpsellEnabled = useIsElevatedAutomationUpsellEnabled({
			isSpaceAdmin,
		});

		useEffect(() => {
			const sectionExpandStates = sessionStorage.getItem(
				sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES,
			);
			if (sectionExpandStates == null || sectionExpandStates?.spaceKey != spaceKey) {
				sessionStorage.setItem(sessionStorageKeys.SPACE_NAV_SECTIONS_EXPANDED_STATES, {
					spaceKey,
					contentTree: true,
					shortcuts: true,
					apps: true,
				});
			}
		}, [spaceKey]);

		const {
			loading: loadingForgeApps,
			extensions: forgeApps,
			error: forgeError,
		} = useExtensionList({
			moduleType: FORGE_MODULE_SPACE_PAGE,
		});

		if (forgeError && !isErrorMarkedAsHandled(forgeError)) {
			getMonitoringClient().submitError(forgeError, {
				attribution: Attribution.ECOSYSTEM,
			});
			markErrorAsHandled(forgeError);
		}

		const { allAppLinks, allVisibleAppLinks, shouldRenderSpaceApps } = getAllAppLinks(
			spaceKey,
			main,
			forgeApps,
			isSpaceAdmin,
		);

		const shortcutsHidden =
			main.filter(
				({ webItemCompleteKey, hidden }) => webItemCompleteKey === SHORTCUTS_KEY && hidden,
			).length > 0;

		const premiumFeaturesWebItemKeys = getPremiumFeaturesWebItemKeys(
			isSpaceAdmin,
			isSiteAdmin,
			isSiteAdminLoading,
			edition,
			isElevationAutomationUpsellEnabled,
		);

		const advancedFeaturesHidden = premiumFeaturesWebItemKeys.every((key) => {
			const item = main.find((webItem) => webItem.webItemCompleteKey === key);
			return item ? item.hidden : true;
		});

		// Determine whether or not the user has seen the changeboarding modal for migrating shortcuts
		const {
			data: migrationOnboardingStateData,
			loading: migrationOnboardingStateLoading,
			error: migrationOnboardingStateError,
		} = useGetOnboardingState(
			Object.values({ ...ONBOARDING_SIDE_NAV_STATE_KEYS }),
			!isMigrateShortcutsEnabled || !isMigrateShortcutsChangeboardingEnabled,
		);

		const { migrateShortcutsChangeBoardingSeen } = deserializeState(migrationOnboardingStateData);

		//check the links to see if blogs is present and return an inverted boolean
		const blogsHidden =
			main.filter(({ webItemCompleteKey, hidden }) => {
				return webItemCompleteKey === BLOG_KEY && hidden === true;
			}).length > 0;

		const showShortcutsSection =
			!loading && !shortcutsHidden && !(isMigrateShortcutsEnabled && quick.length === 0);
		const showMigrateShortcutsChangeboarding =
			!loading &&
			!shortcutsHidden &&
			isMigrateShortcutsEnabled &&
			isMigrateShortcutsChangeboardingEnabled &&
			!migrateShortcutsChangeBoardingSeen &&
			!migrationOnboardingStateError &&
			!migrationOnboardingStateLoading &&
			!isSpaceAdmin &&
			quick.length === 0;

		let sideNavContent: ReactElement;
		if (unhandledError || hasRestrictedError || hasNotFoundError || (!loading && !hasSpace)) {
			sideNavContent = (
				<ErrorBoundary
					attribution={Attribution.DISCO}
					attributes={{
						errorBoundaryId: 'SpaceNavigation-content-error',
					}}
				>
					<ErrorView
						unhandledError={unhandledError}
						hasRestrictedError={hasRestrictedError}
						hasNotFoundError={hasNotFoundError}
					/>
				</ErrorBoundary>
			);
		} else {
			sideNavContent = (
				<ErrorBoundary
					attribution={Attribution.DISCO}
					attributes={{
						errorBoundaryId: 'SpaceNavigation-content',
					}}
				>
					{loading || !name ? (
						<SpaceHeaderSkeleton />
					) : (
						<SpaceHeader
							spaceKey={spaceKey}
							spaceName={name}
							space={space}
							iconPath={iconPath}
							homepageId={homepageId}
							containsExternalCollaborators={containsExternalCollaborators}
							isUserExternalCollaborator={isUserExternalCollaborator}
						/>
					)}
					<NavigationContent>
						<SidebarContent data-vc="space-navigation-sidebar-content">
							{!loading ? <ExperienceSuccess name={APP_NAV_CONTAINER_EXPERIENCE} /> : <Fragment />}
							{isBlogsTurnedOffFromPTLJobChangeboardingEnabled && isSpaceAdmin && blogsHidden && (
								<BlogsToggledOffByPTLChangeboarding spaceKey={spaceKey} />
							)}
							<SpaceLinksContainer>
								{loading ? (
									<ContainerSkeleton header={false} />
								) : (
									<SpaceLinks
										isSpaceAdmin={isSpaceAdmin}
										links={main}
										isSpaceSettingsScreen={isSpaceSettingsScreen}
										isMoreFeaturesEnabled={isMoreFeaturesEnabled}
										isAdvancedFeatures={false}
									/>
								)}
							</SpaceLinksContainer>
							{isMoreFeaturesEnabled &&
							!advancedFeaturesHidden &&
							!loading &&
							!isSiteAdminLoading ? (
								<AdvancedFeatures
									spaceKey={spaceKey}
									isSpaceAdmin={isSpaceAdmin}
									isSiteAdmin={isSiteAdmin}
									links={main}
								/>
							) : (
								<Fragment />
							)}
							{showShortcutsSection && (
								<ShortcutsSection
									isSpaceAdmin={isSpaceAdmin}
									spaceKey={spaceKey}
									links={quick}
									spaceId={space?.id || null}
								/>
							)}
							{showMigrateShortcutsChangeboarding && (
								<MigrateShortcutsChangeboarding
									onDismissClick={() => {
										setOnboardingState({
											key: ONBOARDING_SIDE_NAV_STATE_KEYS.MIGRATE_SHORTCUTS_CHANGEBOARDING_SEEN,
											value: 'true',
										});
									}}
								/>
							)}
							{!loading ? (
								<div data-testid="pageTree" onMouseEnter={PageTreeLoaderOnHover.hydrateOnHover}>
									<SpaceViewsController
										key={spaceKey} // Remount on space change to reset Space views state
										homepageId={homepageId}
										onDragDropSuccess={onDragDropSuccess}
										isPeekingFromBlogs={isBlogNavigation}
									/>
								</div>
							) : (
								<Fragment />
							)}
							{!loading && isBlogTreeUnderContentTreeFFEnabled && !blogsHidden && (
								<Fragment>
									<PageSegmentLoadStart metric={BLOG_TREE_METRIC} />
									<BlogTree spaceKey={spaceKey} />
								</Fragment>
							)}
							{!loading &&
							(process.env.REACT_SSR || window.__SSR_RENDERED__ || !loadingForgeApps) &&
							shouldRenderSpaceApps ? (
								<ErrorBoundary attribution={Attribution.ECOSYSTEM}>
									<PageSegmentLoadStart metric={SPACE_APPS_METRIC} />
									<SpaceApps
										spaceKey={spaceKey}
										isSpaceAdmin={isSpaceAdmin}
										allAppLinks={allAppLinks}
										allVisibleAppLinks={allVisibleAppLinks}
									/>
								</ErrorBoundary>
							) : (
								<Fragment />
							)}
						</SidebarContent>
					</NavigationContent>
					<PersistentUpgradeButton />
					<PersistentInvitePeopleButton source="pageTree" />
					<GuestRequestToUpgradeSideNav />
				</ErrorBoundary>
			);
		}

		return (
			<ErrorBoundary
				attribution={Attribution.DISCO}
				attributes={{
					errorBoundaryId: 'SpaceNavigation-all',
				}}
			>
				<SpotlightTarget name="live-pages-changeboarding.content-tree">
					<SpaceNavigationContainer
						data-testid={SPACE_NAV_ID}
						data-vc="space-navigation"
						onMouseEnter={handleMouseEnter}
						{...ssrPlaceholderIdProp}
					>
						<PerformanceStart
							subject={PERFORMANCE_SUBJECT_navigation}
							subjectId="SpaceViewLoading"
						/>
						<SideNavigation label={intl.formatMessage(i18n.navRegionLabel)}>
							{sideNavContent}
						</SideNavigation>
						<GeneralShortcutListener
							key="overview-shortcut"
							accelerator={SPACE_OVERVIEW_SHORTCUT}
							listener={onOverviewShortcutTrigger}
						/>
						<PerformanceEnd
							subject={PERFORMANCE_SUBJECT_sideNavigationFMP}
							subjectId="SideNavigationFMP"
							includeFeatureFlags
						/>
						{!loading && <PageSegmentLoadEnd key={spaceKey} metric={SPACE_NAVIGATION_METRIC} />}
						{unhandledError && <ErrorDisplay error={unhandledError} />}
						{(process.env.REACT_SSR || window.__SSR_RENDERED__) && (
							<FocusToCurrentPageTreeLinkItemSSRInlineScript />
						)}
					</SpaceNavigationContainer>
				</SpotlightTarget>
			</ErrorBoundary>
		);
	},
);

function isNonNullable<T extends object | null | undefined>(x: T): x is NonNullable<T> {
	return Boolean(x);
}
